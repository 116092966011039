import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwl';
import React from 'react';
import { copyToClipboard } from '../utils/copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';

const Code = ({ codeString, language }) => {
  const handleClick = () => {
    copyToClipboard(codeString);
  };

  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}>
      {({
        className,
        style,
        tokens,
        getLineProps,
        getTokenProps,
      }) => (
        <div className="d-flex">
          <div className="flex-grow-1 overflow-auto mr-2">
            <pre className={className} style={style}> 
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  <span className="line-number">{language === "console" ? ">" : i + 1}</span>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          </div>
          <div className="copy-code"><FaCopy onClick={handleClick}/></div>
        </div>
      )}
    </Highlight>
  );
};

export default Code;